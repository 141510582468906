<template>
  <a @click="trigger">
    <slot>
      <span id="add-vendor-link"> {{ $t('create') }} {{ $tc(type) }} </span>
    </slot>
    <FlotoDrawer
      ref="drawer"
      :open="isDrawerOpen"
      width="75%"
      @hide="cancelAction"
    >
      <template v-slot:title> {{ $t('create') }} {{ $tc(type) }} </template>
      <UserForm
        ref="formRef"
        :default-value="defaultUser"
        :processing="processing"
        :type="type"
        :excluded="['mfa_enrolled']"
        @submit="handleSubmit"
      />
      <template v-slot:actions="{ hide }">
        <MButton
          id="add-update-btn"
          type="submit"
          :loading="processing"
          class="mx-1"
          @click="submitForm"
        >
          {{ $tc('create') }}
        </MButton>
        <MButton id="canel-btn" variant="default" class="mx-1" @click="hide">
          {{ $tc('cancel') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </a>
</template>
<script>
import Bus from '@utils/emitter'
import CloneDeep from 'lodash/cloneDeep'
import { OrganizationComputed } from '@state/modules/organization'
import UserForm from '@modules/users/components/user-form'
import { createUserApi } from '@modules/users/users-api'

export default {
  name: 'UserCreateDrawer',
  components: { UserForm },
  props: {
    name: { type: String, default: '' },
  },
  data() {
    this.type = 'requester'
    this.defaultData = {
      address: {},
    }
    return {
      isDrawerOpen: false,
      formData: {},
      processing: false,
    }
  },
  computed: {
    defaultUser() {
      return {
        name: this.name,
        allowToLogin: true,
        scope: 'global_access',
        assetAccessLevel: 'global_access',
        timezone: this.defaultTimeZone,
        userType: 'requester',
        allowDelegateApproval: true,
        dateTimeFormat: this.organization.dateTimeFormat,
        dateFormat: this.organization.dateFormat,
        timeFormat: this.organization.timeFormat,
      }
    },
    ...OrganizationComputed,
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
        setTimeout(() => {
          if (this.$refs.formRef) {
            this.$refs.formRef.generateProfilePic()
          }
        }, 300)
      }, 400)
      this.formData = CloneDeep(this.defaultData)
    },
    cancelAction() {
      this.isDrawerOpen = false
    },
    submitForm() {
      this.$refs.formRef.submit()
    },
    handleSubmit(user) {
      this.processing = true
      return createUserApi(this.type, user)
        .then((data) => {
          Bus.$emit('app:form:reqeusteremail:user:create', data)
          this.isDrawerOpen = false
        })
        .finally(() => (this.processing = false))
    },
  },
}
</script>
