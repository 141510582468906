<template>
  <MPopover
    :open="isPolicyPopoverOpen"
    :placement="policyPopoverPosition"
    overlay-class-name="-mr-6"
  >
    <template v-slot:trigger>
      <FlotoFormItem
        ref="passwordFormItem"
        :validation-label="validationLabel"
        v-bind="attrs"
      >
        <MInput
          v-model="innerValue"
          :read-only="isReadOnly"
          :type="type"
          v-bind="attrs"
          @paste="onPaste"
          @copy="onCopy"
          @focus="handleInputFocus"
          @blur="handleInputBlur"
        >
          <template
            v-for="(_, name) in $scopedSlots"
            v-slot:[name]="nestedSlot"
          >
            <slot :name="name" v-bind="nestedSlot" />
          </template>
          <template v-slot:suffix>
            <MTooltip v-if="isCapsLock">
              <template v-slot:trigger>
                <MIcon
                  name="exclamation-triangle"
                  class="text-secondary-orange mx-1"
                />
              </template>
              {{ 'Caps Lock' }}
            </MTooltip>
            <MIcon
              v-if="!alwaysVisible && !disableShowPassword"
              :name="`${type === 'password' ? 'eye' : 'eye-slash'}`"
              class="cursor-pointer text-neutral-light"
              @click="toggleType"
            />
          </template>
        </MInput>
      </FlotoFormItem>
    </template>
    <ul v-if="passwordPolicy" class="policy-rules-list">
      <li v-for="policy in Object.keys(passwordPolicy)" :key="policy">
        <MIcon
          :name="invalidPolicies.indexOf(policy) >= 0 ? 'times' : 'check'"
          :class="{
            'text-secondary-red': invalidPolicies.indexOf(policy) >= 0,
            'text-secondary-green': invalidPolicies.indexOf(policy) === -1,
          }"
          class="mr-2"
          size="lg"
        />
        <span>
          {{ getPolicyText(policy) }}
        </span>
      </li>
    </ul>
  </MPopover>
</template>

<script>
import Pick from 'lodash/pick'
import { getEncryptedPassword, getDecryptedPassword } from '@utils/password'
import { getPasswordPolicyApi } from '@modules/organization/password-policy-api'
import { validatePasswordWithPolicies } from '@src/validations'

const policyTextMap = {
  minLength: 'min_length',
  minUpperCaseLength: 'min_upper_case_length',
  minLowerCaseLength: 'min_lower_case_length',
  minNumericCharacterLength: 'min_numeric_character_length',
  minSpecialCharacterLength: 'min_special_character_length',
  // enableSpecialChar: 'special_characters',
  // enableAlphabetAndNumber: 'alphanumeric',
  // enableMixCase: 'mixcase',
}

export default {
  name: 'PasswordInput',
  inheritAttrs: false,
  model: { event: 'update' },
  props: {
    value: { type: String, default: undefined },
    usePolicy: { type: Boolean, default: false },
    policyPopoverPosition: { type: String, default: 'left' },
    validationLabel: {
      type: String,
      default() {
        return this.$tc('password')
      },
    },
    // eslint-disable-next-line
    enabledEncryption: { type: Boolean, default: true },
    alwaysVisible: { type: Boolean, default: false },
    // eslint-disable-next-line
    allowPaste: { type: Boolean, default: true },
    disableShowPassword: { type: Boolean, default: false },
  },
  data() {
    this.policyTextMap = policyTextMap
    this.availablePolicies = [
      'minLength',
      'minUpperCaseLength',
      'minLowerCaseLength',
      'minNumericCharacterLength',
      'minSpecialCharacterLength',
    ]
    return {
      type: this.alwaysVisible ? 'text' : 'password',
      passwordPolicy: null,
      isFocused: false,
      isReadOnly: true,
      isCapsLock: false,
    }
  },
  computed: {
    attrs() {
      if (this.usePolicy && this.passwordPolicy) {
        const { ...attrs } = this.$attrs
        attrs.rules = {
          required: true,
          password: this.passwordPolicy,
        }
        return attrs
      }
      return this.$attrs
    },
    isPolicyPopoverOpen() {
      return this.usePolicy && this.isFocused && this.passwordPolicy !== null
    },
    innerValue: {
      get() {
        // decrypt value
        const value = this.enabledEncryption
          ? getDecryptedPassword(this.value)
          : this.value
        return value
      },
      set(v) {
        const value = this.enabledEncryption ? getEncryptedPassword(v) : v
        this.$emit('update', value)
      },
    },
    listeners() {
      const { change, input, update, ...listeners } = this.$listeners
      return listeners
    },
    invalidPolicies() {
      const value = this.innerValue || ''
      return validatePasswordWithPolicies(value, {
        policies: this.passwordPolicy,
        getInvalidPolicies: true,
      })
    },
  },
  watch: {
    usePolicy: {
      immediate: true,
      handler: 'getPolicy',
    },
  },
  created() {
    this.keyupHandler = this.keyupHandler.bind(this)
    this.bindEvents()
  },
  mounted() {
    this.$once('hook:beforeDestroy', () => {
      this.unbindEvents()
    })
  },
  methods: {
    onPaste(e) {
      if (!this.allowPaste) {
        e.preventDefault()
      }
    },
    onCopy(e) {
      e.preventDefault()
    },
    bindEvents() {
      document.addEventListener('keyup', this.keyupHandler)
    },
    keyupHandler(event) {
      if (event.key === 'CapsLock' && this.isCapsLock) {
        this.isCapsLock = false
        return
      }
      if (event.getModifierState('CapsLock')) {
        this.isCapsLock = true
      } else {
        this.isCapsLock = false
      }
    },
    unbindEvents() {
      document.removeEventListener('keyup', this.keyupHandler)
    },
    handleInputFocus() {
      this.isFocused = true
      this.isReadOnly = false
    },
    handleInputBlur() {
      this.isFocused = false
      // if (this.invalidPolicies.length) {
      //   this.$refs.passwordFormItem.addError(
      //     this.$t('passwordPolicy.doesnotmatch')
      //   )
      // } else {
      //   this.$refs.passwordFormItem.validate()
      // }
    },
    getPolicyText(policy) {
      const args = {}
      args.length = this.passwordPolicy[policy]
      return this.$t(`passwordPolicy.${this.policyTextMap[policy]}`, args)
    },
    getPolicy() {
      if (this.usePolicy) {
        getPasswordPolicyApi().then((data) => {
          let policyData = {}
          Object.keys(Pick(data, this.availablePolicies)).forEach((policy) => {
            if (data[policy]) {
              policyData[policy] = data[policy]
            }
          })
          this.passwordPolicy = Object.keys(policyData).length
            ? policyData
            : null
        })
      } else {
        this.passwordPolicy = {}
      }
    },
    toggleType() {
      if (this.disableShowPassword) {
        this.type = 'password'
      } else {
        if (this.type === 'text') {
          this.type = 'password'
        } else {
          this.type = 'text'
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.policy-rules-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;

    @apply py-2;
  }
}
</style>
