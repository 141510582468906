<script>
import Bus from '@utils/emitter'
import Pick from 'lodash/pick'
import GroupBy from 'lodash/groupBy'
import CloneDeep from 'lodash/cloneDeep'
import IntersectionWith from 'lodash/intersectionWith'
import IsEqual from 'lodash/isEqual'
import Size from 'lodash/size'
import Invert from 'lodash/invert'
import { getFormRulesApi } from '@modules/form-rules/form-rules-api'
import { flattenFields, mandatorySystemFields, getFieldIds } from '@data/form'
import {
  executeQualification,
  performActions,
} from '@data/qualification-execution'
import { PreferenceComputed } from '@state/modules/preference'
import { FormComputed } from '@state/modules/form'
import { getApi } from '@modules/ticket/ticket-api'
import { authComputed } from '@state/modules/auth'
import {
  eventTypeMap,
  formRulesSupportedModules,
  formRulesTabMap,
} from '@data/form-rules'
import { changeExteranSystemField } from '@modules/service-catalog/utils/requester-field-data'
import { getGlobalUserApi } from '@modules/users/users-api'
import { searchCustomScriptApi } from '@modules/form/custom-script-api'
export default {
  name: 'FormRulesProvider',
  provide() {
    const formRulesContext = {
      name: 'formRules',
      applyFormRules: this.handleApplyFormRulesOnFieldChange,
      handleHideEditFormModal: this.handleHideEditFormModal,
      handleShowEditFormModal: this.handleShowEditFormModal,
      executeFormRules: this.handleExecuteFormRules,
      isFormRuleQualified: this.handleCheckIsFormRuleQualified,
      setInitState: this.setInitState,
      setServiceCatalogFormFieldsForDetails:
        this.setServiceCatalogFormFieldsForDetails,
      fetchServiceCatalogFormRules: this.fetchServiceCatalogFormRules,
      getFormRules: this.getFormRules,
      handleRemoveExecutedScript: this.handleRemoveExecutedScript,
      handleRemoveValueProperyFieldRulesState:
        this.handleRemoveValueProperyFieldRulesState,
    }
    Object.defineProperty(formRulesContext, 'fieldRulesState', {
      enumerable: true,
      get: () => {
        return this.fieldRulesState
      },
    })
    Object.defineProperty(formRulesContext, 'updatedResource', {
      enumerable: true,
      get: () => {
        return this.updatedResource
      },
    })
    Object.defineProperty(formRulesContext, 'unTouchedResource', {
      enumerable: true,
      get: () => {
        return this.unTouchedResource
      },
    })
    Object.defineProperty(formRulesContext, 'showEditFormModal', {
      enumerable: true,
      get: () => {
        return this.showEditFormModal
      },
    })
    Object.defineProperty(formRulesContext, 'hasTicketFormRules', {
      enumerable: true,
      get: () => {
        return !!(
          formRulesSupportedModules.indexOf(this.moduleName) >= 0 &&
          this.hasTicketFormRules
        )
      },
    })
    Object.defineProperty(formRulesContext, 'hasServiceCatalogFormRules', {
      enumerable: true,
      get: () => {
        return !!(
          formRulesSupportedModules.indexOf(this.moduleName) >= 0 &&
          this.hasServiceCatalogFormRules
        )
      },
    })
    Object.defineProperty(formRulesContext, 'serviceCatalogFormFields', {
      enumerable: true,
      get: () => {
        return this.serviceCatalogFormFields
      },
    })
    Object.defineProperty(formRulesContext, 'customScripts', {
      enumerable: true,
      get: () => {
        return this.customScripts
      },
    })
    Object.defineProperty(formRulesContext, 'formRules', {
      enumerable: true,
      get: () => {
        return this.formRules
      },
    })
    return { formRulesContext }
  },
  props: {
    moduleName: {
      type: String,
      default() {
        return this.$constants.REQUEST
      },
    },
    parentResourceId: { type: Number, default: undefined },
    customFields: { type: [Array], default: undefined },
  },
  data() {
    return {
      formRules: {
        on_form_load: [],
        on_field_change: [],
        on_form_submit: [],
      },
      allAvailableFormRules: [],
      isFormRulesLoaded: false,
      fieldRulesState: {},
      updatedResource: {},
      unTouchedResource: {},
      showEditFormModal: false,
      serviceCatalogFormFields: [],
      hasTicketFormRules: false,
      hasServiceCatalogFormRules: false,
      customScripts: {},
      isCustomScriptsLoaded: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    ...FormComputed,
    ...authComputed,
    formFields() {
      if (this.customFields) {
        return this.customFields
      }
      if (formRulesSupportedModules.indexOf(this.moduleName) === -1) {
        return []
      }
      const fields = this[`${this.moduleName}Fields`]({
        exclude: [],
      })
      return [
        ...fields,
        ...(this.hasServiceCatalogFormRules
          ? this.serviceCatalogFormFields
          : []),
      ]
    },
    flattenFieldsArray() {
      let fields = this.formFields
      if (this.moduleName === this.$constants.CHANGE) {
        fields = [...this.formFields, ...changeExteranSystemField()]
      }
      return flattenFields(fields)
    },
  },
  watch: {
    '$route.name': {
      handler: 'setInitState',
    },
    flattenFieldsArray: {
      handler(newValue, preValue) {
        const newSize = Size(newValue)
        const prevSize = Size(preValue)
        if (newSize !== prevSize) {
          this.prepairDefaultRulesState()
        }
      },
    },
  },
  created() {
    const updateFieldRulesStateHandler = ({
      fieldKey,
      actionKey,
      actionValue,
    }) => {
      this.handleUpdateFieldRulesState(fieldKey, actionKey, actionValue)
    }
    Bus.$on('form:helper:hidden:options', updateFieldRulesStateHandler)
    Bus.$on('form:helper:visible:options', updateFieldRulesStateHandler)

    this.$once('hook:beforeDestroy', () => {
      Bus.$off('form:helper:hidden:options', updateFieldRulesStateHandler)
      Bus.$off('form:helper:visible:options', updateFieldRulesStateHandler)
    })
    this.getFormRules().then(() => {
      this.getCustomScripts().then(() => {
        this.prepairDefaultRulesState()
      })
    })
  },
  methods: {
    handleUpdateFieldRulesState(fieldKey, actionKey, actionValue) {
      this.fieldRulesState = {
        ...this.fieldRulesState,
        [fieldKey]: {
          ...this.fieldRulesState[fieldKey],
          [actionKey]: actionValue,
        },
      }
    },
    setInitState() {
      this.updatedResource = {}
      this.unTouchedResource = {}
      this.prepairDefaultRulesState()
    },
    pickResourceFieldValue(resource) {
      const resourceData = Pick(
        {
          ...resource,
          requester: (resource.requesterData || {}).name || resource.requester,
        },
        [
          ...(getFieldIds(this.flattenFieldsArray) || []),
          'id',
          'name',
          'requester',
          'requesterId',
          ...(this.moduleName === this.$constants.CHANGE
            ? ['changeReviewer', 'changeManager', 'changeImplementor']
            : ['requestType']),
          ...(this.moduleName === this.$constants.TASK
            ? ['notifyBeforeHoursTimeUnit', 'estimatedTimeUnit']
            : []),
        ]
      )
      return resourceData
    },
    getCustomScripts() {
      const customScriptIds = []
      this.allAvailableFormRules.forEach((rule) => {
        rule.actions.forEach((action) => {
          if (
            action.action === 'run_custom_script' &&
            customScriptIds.indexOf(action.fieldValue) === -1
          ) {
            customScriptIds.push(action.fieldValue)
          }
        })
      })
      if (!customScriptIds.length) {
        this.isCustomScriptsLoaded = true
        return Promise.resolve()
      }
      return searchCustomScriptApi({ ids: customScriptIds }).then((data) => {
        const customScripts = {}
        const scripts = data.items || []
        scripts.forEach((script) => {
          customScripts[script.id] = script
        })
        this.customScripts = customScripts
        this.isCustomScriptsLoaded = true
        return customScripts
      })
    },
    setServiceCatalogFormFieldsForDetails(fields) {
      this.serviceCatalogFormFields = fields.filter(
        (f) => !f.isSystemField && f.inputType !== 'api'
      )
    },
    fetchServiceCatalogFormRules(serviceCatalogId) {
      return this.getFormRules(
        this.$constants.SERVICE_CATALOG,
        serviceCatalogId
      )
    },
    getFormRules(moduleName, parentResourceId) {
      try {
        const module = moduleName || this.moduleName
        const parentId = parentResourceId || this.parentResourceId
        if (formRulesSupportedModules.indexOf(this.moduleName) === -1) {
          this.isFormRulesLoaded = true
          return Promise.resolve()
        }
        return getFormRulesApi(
          {
            moduleName: module,
            ...(parentId
              ? {
                  parentId,
                }
              : {}),
            name: '',
          },
          undefined,
          undefined,
          !this.loggedIn
        )
          .then((data) => {
            const rules = data.items.filter((i) => i.enabled)
            if (rules.length) {
              // check is service catalog form rule call
              if (moduleName && parentResourceId) {
                this.hasServiceCatalogFormRules = true
                const allRules = [...this.allAvailableFormRules, ...rules]
                const formRulesByEvents = GroupBy(allRules, 'formRuleEvent')
                this.formRules = formRulesByEvents
                this.allAvailableFormRules = allRules
              } else {
                this.hasTicketFormRules = true
                const formRulesByEvents = GroupBy(rules, 'formRuleEvent')
                this.formRules = formRulesByEvents
                this.allAvailableFormRules = rules
              }
            } else {
              if (moduleName && parentResourceId) {
                this.hasServiceCatalogFormRules = false
              } else {
                this.hasTicketFormRules = false
                this.allAvailableFormRules = []
                this.formRules = {
                  on_form_load: [],
                  on_field_change: [],
                  on_form_submit: [],
                }
              }
            }
            return this.formRules
          })
          .catch(() => {
            return Promise.resolve()
          })
          .finally(() => {
            return (this.isFormRulesLoaded = true)
          })
      } catch (e) {
        this.isFormRulesLoaded = true
        return Promise.resolve()
      }
    },
    prepairDefaultRulesState() {
      const defaultState = {
        hide: false,
        mandatory: false,
        disable: false,
        isDirty: false,
      }
      const fieldRulesState = {}
      Object.keys(Invert(formRulesTabMap(this.moduleName))).forEach((tab) => {
        fieldRulesState[tab] = defaultState
      })
      this.flattenFieldsArray.forEach((f) => {
        const fieldKey = f.isSystemField ? f.paramName : f.id
        fieldRulesState[fieldKey] = defaultState
      })
      this.fieldRulesState = fieldRulesState
    },
    availableQualifiedRules({
      executionTypes,
      userTypes,
      eventType,
      triggerFields,
      ...rest
    }) {
      let rules = this.formRules[eventType] || []
      if (eventType === eventTypeMap['change']) {
        rules = this.filterFormRulesByTriggerFields(rules, triggerFields)
      }
      rules = this.filterFormRulesByExecutionOn(rules, executionTypes)
      rules = this.filterFormRulesByApplicableFor(rules, userTypes)
      return rules
    },
    handleCheckIsFormRuleQualified({
      executionTypes,
      userTypes,
      eventType,
      triggerFields,
      ...rest
    }) {
      const rules = !!(
        this.availableQualifiedRules({
          executionTypes,
          userTypes,
          eventType,
          triggerFields,
          ...rest,
        }) || []
      ).length
      return rules
    },
    filterFormRulesByTriggerFields(rulesList, triggerFields) {
      if (
        triggerFields.indexOf('requester') >= 0 &&
        this.moduleName === this.$constants.SERVICE_CATALOG
      ) {
        triggerFields = triggerFields.concat('requesterId')
      }
      const rules = (rulesList || []).filter((rule) => {
        const isAvailable = IntersectionWith(
          triggerFields,
          rule.fieldsChangedTrigger,
          IsEqual
        )
        return !!isAvailable.length
      })
      return rules || []
    },
    filterFormRulesByExecutionOn(rulesList, executionTypes) {
      const rules = rulesList.filter((rule) => {
        return executionTypes.indexOf(rule.formRuleExecutionOn) >= 0
      })
      return rules || []
    },
    filterFormRulesByApplicableFor(rulesList, usersTypes) {
      const rules = rulesList.filter((rule) => {
        return usersTypes.indexOf(rule.formRuleUserType) >= 0
      })
      return rules || []
    },
    handleApplyFormRulesOnFieldChange(resource, options = {}, ruleConfig) {
      const promiseList = []
      if (options.externalApiQual && resource.id) {
        promiseList.push(
          getApi(this.moduleName, resource.id, this.isPortalLogin).then(
            (data) => {
              this.unTouchedResource = data
              return data
            }
          )
        )
      } else {
        promiseList.push(Promise.resolve(resource))
      }
      if (resource.requesterId) {
        promiseList.push(
          getGlobalUserApi(resource.requesterId, { archived: true })
        )
      }
      Promise.all(promiseList).then(([ticket, requester]) => {
        this.updatedResource = this.pickResourceFieldValue({
          ...ticket,
          ...resource,
        })
        if (!resource.requesterId && ticket.requesterId) {
          getGlobalUserApi(ticket.requesterId, { archived: true }).then(
            (data) => {
              if (!options.viewOnlyMode) {
                this.showEditFormModal = true
              }
              this.handleExecuteFormRules(this.updatedResource, {
                ...ruleConfig,
                requesterDetails: data,
              })
            }
          )
        } else {
          if (!options.viewOnlyMode) {
            this.showEditFormModal = true
          }
          this.handleExecuteFormRules(this.updatedResource, {
            ...ruleConfig,
            requesterDetails: requester,
          })
        }
      })
    },
    handleExecuteFormRules(
      resource,
      {
        executionTypes,
        userTypes,
        eventType,
        triggerFields,
        requesterDetails,
        ...rest
      }
    ) {
      this.updatedResource = this.pickResourceFieldValue(resource)
      const requesterDetailsWithCustomField =
        requesterDetails && requesterDetails.id
          ? {
              ...(requesterDetails || {}),
              ...(requesterDetails.fieldValueDetails
                ? { ...requesterDetails.fieldValueDetails }
                : {}),
            }
          : {}
      const executedResultList = []
      const availableRules =
        this.availableQualifiedRules({
          executionTypes,
          userTypes,
          eventType,
          triggerFields,
          ...rest,
        }) || []
      availableRules.forEach((rule) => {
        const qualResult = executeQualification(
          rule.qualifications,
          this.updatedResource,
          requesterDetailsWithCustomField
        )
        const actions = rule.actions.map((action) => {
          return {
            ...action,
            ...(action.action === 'run_custom_script'
              ? {
                  customScript: this.customScripts[action.fieldValue],
                }
              : {}),
          }
        })
        executedResultList.push({
          isQualified: qualResult,
          reversibleAction: rule.reversibleAction,
          actions,
        })
      })
      if ((executedResultList || []).length) {
        const fieldRulesState = this.fieldRulesState
        if ((triggerFields || []).length) {
          triggerFields.forEach((triggerField) => {
            if (
              fieldRulesState[triggerField] &&
              fieldRulesState[triggerField].isDirty
            ) {
              fieldRulesState[triggerField] = {
                ...fieldRulesState[triggerField],
                setValue: this.updatedResource[triggerField],
              }
            }
          })
        }

        const result = performActions(
          this.moduleName,
          executedResultList,
          this.flattenFieldsArray,
          fieldRulesState,
          this.updatedResource
        )
        this.fieldRulesState = CloneDeep(result)
        const hiddenFieldIds = []
        // find hidden field
        Object.keys(result).forEach((f) => {
          if (result[f].hide === true) {
            hiddenFieldIds.push(+f)
          }
        })
        if (hiddenFieldIds.length) {
          // set default value for hidden field before hide
          const defaultData = {}
          const fields = this.flattenFieldsArray.filter(
            (f) => hiddenFieldIds.indexOf(f.id) >= 0
          )
          flattenFields(fields).forEach((f) => {
            if (f.required) {
              return
            }
            // for system field find default value from computed value
            // mandatorySystemFields is a map of field, we have to set defalut value on hide
            if (
              f.isSystemField &&
              mandatorySystemFields.indexOf(f.inputType) >= 0
            ) {
              defaultData[f.paramName] = (
                (this[`${f.inputType}Options`] || []).find((v) => v.default) ||
                {}
              ).key
            } else {
              if (
                'clearValue' in
                  this.fieldRulesState[f.isSystemField ? f.paramName : f.id] &&
                this.fieldRulesState[f.isSystemField ? f.paramName : f.id]
                  .isDirty
              ) {
                defaultData[f.isSystemField ? f.paramName : f.id] =
                  this.fieldRulesState[
                    f.isSystemField ? f.paramName : f.id
                  ].clearValue
              } else if (
                'setValue' in
                  this.fieldRulesState[f.isSystemField ? f.paramName : f.id] &&
                this.fieldRulesState[f.isSystemField ? f.paramName : f.id]
                  .isDirty
              ) {
                defaultData[f.isSystemField ? f.paramName : f.id] =
                  this.fieldRulesState[
                    f.isSystemField ? f.paramName : f.id
                  ].setValue
              } else {
                defaultData[f.isSystemField ? f.paramName : f.id] =
                  f.defaultValue
              }
            }
          })
          // set update form data with default data for hidden field
          this.updatedResource = {
            ...this.updatedResource,
            ...defaultData,
          }
        }
      }
    },
    handleHideEditFormModal() {
      this.showEditFormModal = false
      this.updatedResource = {}
    },
    handleShowEditFormModal(resource = {}) {
      this.updatedResource = this.pickResourceFieldValue({
        ...resource,
      })
      Bus.$emit('app:collapse:additional:info')
      setTimeout(() => {
        this.showEditFormModal = true
      }, 200)
    },
    handleRemoveExecutedScript(script) {
      this.fieldRulesState = {
        ...this.fieldRulesState,
        executableCustomScripts:
          this.fieldRulesState.executableCustomScripts.filter(
            (s) => s !== script
          ),
      }
    },
    handleRemoveValueProperyFieldRulesState(fieldKey) {
      delete this.fieldRulesState[fieldKey].setValue
      delete this.fieldRulesState[fieldKey].clearValue
    },
  },
  render() {
    if (
      this.isFormRulesLoaded &&
      this.isCustomScriptsLoaded &&
      this.$scopedSlots.default
    ) {
      return this.$scopedSlots.default({})
    }
    return null
  },
}
</script>
